import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes.json";
import "./CreditaPlatform.css";

const CoinPlatform = () => {
  const navigate = useNavigate();

  const handleBorrowClick = () => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      navigate(routes.REQUESTS);
    } else {
      navigate(routes.LOGIN);
    }
  };

  const handleLendClick = () => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      navigate(routes.REQUESTS);
    } else {
      navigate(routes.LOGIN);
    }
  };

  return (
    <div className="web3-container">
      <div className="web3-header">
        <h1 className="web3-title">Financial Freedom, Redefined.</h1>
        <p className="web3-subtitle">
          Use your digital assets as collateral to borrow crypto from lenders.
          Repay your loan, and you get your digital asset back.
        </p>
      </div>
      <div className="web3-cards">
        <div className="web3-card">
          <h3>Borrow</h3>
          <p>Get access to Fiat currencies</p>
          <button className="web3-button" onClick={handleBorrowClick}>
            →
          </button>
        </div>
        <div className="web3-card">
          <h3>Lend</h3>
          <p>Earn interest on your money, Get access to Web3 borrowers</p>
          <button className="web3-button" onClick={handleLendClick}>
            →
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoinPlatform;
