import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./Arena.css";
import config from "../../config";

const Arena = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [collateralLogos, setCollateralLogos] = useState([]);
  const [filters, setFilters] = useState({
    asset: "All assets",
    type: "borrow",
    duration: "All durations",
    interest: "All interests",
  });
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);

  const fetchUserId = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) return;

    try {
      const response = await fetch(`${config.baseUrl}/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setCurrentUserId(userData.id);
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const [requests, logos] = await Promise.all([
        fetch(`${config.baseUrl}/v1/credita/requests`, {
          headers: { accept: "application/json" },
        }).then((res) => res.json()),
        fetch("/data/cryptoData.json").then((res) => res.json()),
      ]);

      const filteredRequests = requests.filter(
        (request) =>
          request.status === "WAITING_BORROW" ||
          request.status === "WAITING_LEND"
      );

      const mappedRequests = filteredRequests.map((request) => ({
        ...request,
        type: request.type === "LEND" ? "BORROW" : "LEND",
      }));

      setCryptoData(mappedRequests);
      setCollateralLogos(logos);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserId();
    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    let data = cryptoData;

    if (filters.asset !== "All assets") {
      data = data.filter((deal) => deal.collateral.includes(filters.asset));
    }
    if (filters.type) {
      const targetType =
        filters.type.toUpperCase() === "BORROW" ? "LEND" : "BORROW";
      data = data.filter((deal) => deal.type === targetType);
    }
    if (filters.duration !== "All durations") {
      data = data.filter((deal) => {
        const duration = parseInt(deal.duration, 10);
        switch (filters.duration) {
          case "<15 days":
            return duration < 15;
          case "15-30 days":
            return duration >= 15 && duration <= 30;
          case "30-60 days":
            return duration > 30 && duration <= 60;
          case ">60 days":
            return duration > 60;
          default:
            return true;
        }
      });
    }
    if (filters.interest !== "All interests") {
      data = data.filter((deal) => {
        const interest = parseFloat(deal.interest);
        switch (filters.interest) {
          case "0-5%":
            return interest >= 0 && interest < 5;
          case "5-10%":
            return interest >= 5 && interest <= 10;
          case "+10%":
            return interest > 10;
          default:
            return true;
        }
      });
    }
    if (currentUserId !== null) {
      data = data.filter((deal) => deal.user_id !== currentUserId);
    }

    return data;
  }, [cryptoData, filters, currentUserId]);

  const updateFilter = (key, value) => setFilters({ ...filters, [key]: value });

  const getLogoUrl = (collateral) => {
    const match = collateralLogos.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  const parseCollateral = (collateral) => {
    if (collateral.startsWith("{") && collateral.endsWith("}")) {
      return collateral.slice(1, -1).split(",");
    }
    return [collateral];
  };

  const buttonTexts = {
    borrow: "Lend",
    lend: "Borrow",
  };

  if (loading) return <div className="loader">Loading...</div>;

  return (
    <div className="arena-container">
      <header className="arena-header">
        <div className="borrow-lend-radio-button">
          {["borrow", "lend"].map((type) => (
            <button
              key={type}
              className={`filter-button ${
                filters.type === type ? "active" : ""
              }`}
              onClick={() => updateFilter("type", type)}
            >
              {buttonTexts[type]}
            </button>
          ))}
        </div>

        <div className="arena-filters">
          <select
            className="arena-dropdown"
            value={filters.asset}
            onChange={(e) => updateFilter("asset", e.target.value)}
          >
            <option value="All assets">Collateral</option>
            {["BTC", "ETH", "SOL"].map((asset) => (
              <option key={asset} value={asset}>
                {asset}
              </option>
            ))}
          </select>
          <select
            className="arena-dropdown"
            value={filters.duration}
            onChange={(e) => updateFilter("duration", e.target.value)}
          >
            <option value="All durations">Duration</option>
            <option value="<15 days">&lt;15 days</option>
            <option value="15-30 days">15-30 days</option>
            <option value="30-60 days">30-60 days</option>
            <option value=">60 days">&gt;60 days</option>
          </select>
          <select
            className="arena-dropdown"
            value={filters.interest}
            onChange={(e) => updateFilter("interest", e.target.value)}
          >
            <option value="All interests">Interest</option>
            <option value="0-5%">0-5%</option>
            <option value="5-10%">5-10%</option>
            <option value="+10%">+10%</option>
          </select>
        </div>
      </header>
      <table className="arena-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Amount (USD)</th>
            <th>Duration (days)</th>
            <th>Interest (%)</th>
            <th>Collateral</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((deal, index) => (
            <tr key={index}>
              <td>{deal.user_name}</td>
              <td>{deal.amount}</td>
              <td>{deal.duration}</td>
              <td>{deal.interest}</td>
              <td>
                <div className="collateral-cell">
                  {parseCollateral(deal.collateral).map((collateral, idx) => (
                    <div key={idx} className="collateral-item">
                      <img
                        src={getLogoUrl(collateral)}
                        alt={collateral}
                        loading="lazy"
                        className="collateral-logo"
                      />
                      {collateral}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <Link to={`/request/${deal.order_id}`} state={deal}>
                  {deal.type.toLowerCase()}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Arena;
