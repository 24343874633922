import React, { useState, useEffect } from "react";
import "./UserDetails.css";

const UserDetails = ({ user, totalTransactions, totalLends, totalBorrows }) => {
  const [lendPercentage, setLendPercentage] = useState(0);
  const [borrowPercentage, setBorrowPercentage] = useState(0);

  useEffect(() => {
    if (totalLends !== undefined && totalBorrows !== undefined) {
      const totalAmount = totalLends + totalBorrows;
      if (totalAmount > 0) {
        setLendPercentage((totalLends / totalAmount) * 100);
        setBorrowPercentage((totalBorrows / totalAmount) * 100);
      } else {
        setLendPercentage(0);
        setBorrowPercentage(0);
      }
    }
  }, [totalLends, totalBorrows]);

  const isKYCCompleted = true; // Assuming `kycCompleted` is a boolean in the `user` object

  return (
    <div className="user-details">
      <div className="user-photo">
        <img src={user.profilePhoto} alt="Profile" className="profile-photo" />
      </div>
      <div className="user-info">
        <p>
          <strong>Username:</strong> {user.username}
        </p>
        <p>
          <strong>Email:</strong> {user.email}
        </p>
        <p>
          <strong>KYC:</strong>
          {isKYCCompleted ? (
            <span className="kyc-status kyc-completed">Completed</span>
          ) : (
            <button className="complete-kyc-button">Complete Now</button>
          )}
        </p>
      </div>
      <div className="user-stats">
        {totalTransactions !== undefined && (
          <p>
            <strong>Total Transactions:</strong> {totalTransactions}
          </p>
        )}
        <div className="pie-chart-container">
          <div
            className="pie-chart"
            style={{
              background: `conic-gradient(
                                orange ${lendPercentage}%,
                                green 0 ${borrowPercentage}%
                            )`,
            }}
          ></div>
          <div className="pie-chart-legend">
            <div>
              <span className="legend-color lend"></span> Total Lends: $
              {totalLends}
            </div>
            <div>
              <span className="legend-color borrow"></span> Total Borrows: $
              {totalBorrows}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
