import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./TransactionView.css";

const TransactionView = () => {
  const { orderId } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [error, setError] = useState(null);
  const [collateralLogos, setCollateralLogos] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      fetch(
        `https://credita-backend.vercel.app/v1/credita/transaction/${orderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch transaction");
          }
          return response.json();
        })
        .then((data) => setTransaction(data))
        .catch((err) => setError(err.message));
    }
  }, [orderId]);

  useEffect(() => {
    // Load collateral logos
    fetch("/data/cryptoData.json")
      .then((res) => res.json())
      .then((logos) => setCollateralLogos(logos))
      .catch((err) => console.error("Error fetching collateral logos:", err));
  }, []);

  const getLogoUrl = (collateral) => {
    const match = collateralLogos.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  const parseCollateral = (collateral) => {
    if (
      typeof collateral === "string" &&
      collateral.startsWith("{") &&
      collateral.endsWith("}")
    ) {
      return collateral.slice(1, -1).split(",");
    }
    return Array.isArray(collateral) ? collateral : [collateral];
  };

  if (error) {
    return <div className="transaction-view error">{error}</div>;
  }

  if (!transaction) {
    return (
      <div className="transaction-view loading">Loading transaction...</div>
    );
  }

  const participantDetails =
    transaction.type === "BORROW"
      ? transaction.lend_user_details
      : transaction.borrow_user_details;

  const parsedCollateral = parseCollateral(transaction.collateral);

  return (
    <div className="transaction-view">
      <h2>Transaction Details</h2>
      <h3>Order ID: {transaction.order_id}</h3>
      <table>
        <tbody>
          <tr>
            <td>Type:</td>
            <td>{transaction.type}</td>
          </tr>
          <tr>
            <td>Amount:</td>
            <td>
              {transaction.amount} USD {transaction.currency}
            </td>
          </tr>
          <tr>
            <td>Duration:</td>
            <td>{transaction.duration} days</td>
          </tr>
          <tr>
            <td>Interest:</td>
            <td>{transaction.interest}%</td>
          </tr>
          <tr>
            <td>Collateral:</td>
            <td>
              <div className="collateral-cell">
                {parsedCollateral.map((collateral, index) => (
                  <div key={index} className="collateral-item">
                    <img
                      src={getLogoUrl(collateral)}
                      alt={collateral}
                      loading="lazy"
                      className="collateral-logo"
                    />
                    {collateral}
                  </div>
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td>Status:</td>
            <td>{transaction.status}</td>
          </tr>
          {transaction.request_accepted_date && (
            <tr>
              <td>Order accepted date:</td>
              <td>
                {new Date(
                  transaction.request_accepted_date
                ).toLocaleDateString()}
              </td>
            </tr>
          )}
          <tr>
            <td>Participant:</td>
            <td>
              {participantDetails ? (
                <div className="participant-cell">
                  <img
                    src={participantDetails.picture}
                    alt={participantDetails.name}
                    className="participant-avatar"
                  />
                  <span>{participantDetails.name}</span>
                </div>
              ) : (
                "--"
              )}
            </td>
          </tr>
          {transaction.repayment_date && (
            <tr>
              <td>Repayment Date:</td>
              <td>
                {new Date(transaction.repayment_date).toLocaleDateString()}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionView;
