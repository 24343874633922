import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";  // Import useNavigate
import "@adyen/adyen-web/styles/adyen.css";
import "./AdyenPayment.css";
import { useLocation } from "react-router-dom";

const AdyenPayment = () => {
  const location = useLocation();
  const { amount, orderId } = location.state || {}; // Extract state
  const [paymentMethodsResponse, setPaymentMethodsResponse] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [isContainerMounted, setIsContainerMounted] = useState(false);
  const dropinContainerRef = useRef(null);
  const navigate = useNavigate();  // Initialize navigate
  if (!amount) {
    return <p>Invalid payment details.</p>;
  }

  // Function to dynamically load the Adyen script
  const loadAdyenScript = () => {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src*="adyen.js"]')) {
        resolve(); // Script already exists, resolve immediately
        return;
      }
      const script = document.createElement("script");
      script.src = "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/6.0.0/adyen.js"; // Use the updated version
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Adyen script failed to load."));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const response = await fetch(
          "https://credita-backend.vercel.app/v1/credita/adyen/paymentMethods",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              countryCode: "NL",
              amount: { currency: "EUR", value: amount * 100 }, // Convert to smallest unit (cents)
              shopperLocale: "nl-NL",
            }),
          }
        );
        const data = await response.json();
        console.log("Payment methods response:", data);
        setPaymentMethodsResponse(data);
      } catch (error) {
        console.error("Failed to fetch payment methods:", error);
      }
    };

    fetchPaymentMethods();
  }, [amount]); // Re-fetch if amount changes

  useEffect(() => {
    if (dropinContainerRef.current) {
      setIsContainerMounted(true);
    }
  }, [dropinContainerRef.current]);

  useEffect(() => {
    const initializeCheckout = async () => {
      try {
        await loadAdyenScript(); // Ensure script is loaded

        const { AdyenCheckout, Dropin, Card, PayPal, GooglePay } = window.AdyenWeb;

        if (AdyenCheckout && paymentMethodsResponse) {
          const checkoutInstance = await AdyenCheckout({
            clientKey: "test_2Y6Y5QTMMZAORA5JND2CNL3HBM7I26I6",
            environment: "test",
            paymentMethodsResponse,
            countryCode: "NL", // Mandatory field

            // Integrating the event handlers into the configuration
            onSubmit: async (state, component, actions) => {
              try {
                console.log("state.data:", state.data);
                console.log("amount:", amount);
                console.log("orderId:", orderId);
                const result = await makePaymentsCall(state.data, amount, orderId); // Pass orderNumber
                if (!result.resultCode) {
                  actions.reject();
                  return;
                }
                const { resultCode, action, order, donationToken } = result;
                actions.resolve({
                  resultCode,
                  action,
                  order,
                  donationToken,
                });

                setTimeout(() => {
                  navigate('/user-account');
                }, 6000);

              } catch (error) {
                console.error("onSubmit", error);
                actions.reject();
              }
            },
            onAdditionalDetails: async (state, component, actions) => {
              try {
                const result = await makeDetailsCall(state.data); // Define makeDetailsCall
                if (!result.resultCode) {
                  actions.reject();
                  return;
                }
                const { resultCode, action, order, donationToken } = result;
                actions.resolve({
                  resultCode,
                  action,
                  order,
                  donationToken,
                });
              } catch (error) {
                console.error("onSubmit", error);
                actions.reject();
              }
            },
            onPaymentCompleted: (result, component) => {
              console.info(result, component);
            },
            onPaymentFailed: (result, component) => {
              console.info(result, component);
            },
            onError: (error, component) => {
              console.error(error.name, error.message, error.stack, component);
            },
          });

          // Configure and mount the Drop-in component
          const dropin = new Dropin(checkoutInstance, {
            paymentMethodComponents: [Card, PayPal, GooglePay],
            paymentMethodsConfiguration: {
              card: {
                hasHolderName: true,
                holderNameRequired: true,
                installmentOptions: [
                  { value: 3, label: "3 installments" },
                  { value: 6, label: "6 installments" },
                ],
                onValidationError: (error) => {
                  console.error("Card validation error:", error);
                },
              },
            },
          });

          dropin.mount(dropinContainerRef.current); // Mount Drop-in component
          setCheckout(checkoutInstance); // Save instance to state
        }
      } catch (error) {
        console.error("Error initializing AdyenCheckout:", error);
      }
    };

    if (paymentMethodsResponse && isContainerMounted) {
      initializeCheckout();
    }
  }, [paymentMethodsResponse, isContainerMounted]);

  // Function to make the API call to process the payment
  const makePaymentsCall = async (paymentData, amount, orderNumber) => {
    try {
      const response = await fetch("https://credita-backend.vercel.app/v1/credita/adyen/payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          amount: {
            currency: "USD",
            value: amount * 100, // Convert amount to cents (smallest unit)
          },
          reference: "dummy-reference", // Use the actual order number here
          paymentMethod: paymentData.paymentMethod,
          returnUrl: "http://localhost:3000/",
        }),
      });

      if (!response.ok) {
        throw new Error("Payment processing failed");
      }
      return await response.json();
    } catch (error) {
      console.error("Payment API call failed:", error);
      throw error;
    }
  };

  return (
    <div style={{ padding: '30px' }}>
      <h1>Payment</h1>
      <p>You will pay $ {amount}</p>
      <div ref={dropinContainerRef} />
    </div>
  );
};

export default AdyenPayment;
