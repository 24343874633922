import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import TransactionView from "../TransactionView/TransactionView.jsx";
import "./UserTransactions.css";

const UserTransactions = ({
  borrowTransactions = [],
  lendTransactions = [],
}) => {
  const [filter, setFilter] = useState("");
  const [collateralLogos, setCollateralLogos] = useState([]);
  const navigate = useNavigate();
  const currentUserId = parseInt(localStorage.getItem("userId"), 10);

  const statusMap = {
    INPROGRESS: "In Progress",
    COMPLETED: "Completed",
    CANCELLED: "Cancelled",
    WAITING_LEND: "Waiting Lend",
    WAITING_BORROW: "Waiting Borrow",
  };

  // Fetch collateral logos
  useEffect(() => {
    fetch("/data/cryptoData.json")
      .then((res) => res.json())
      .then((logos) => setCollateralLogos(logos))
      .catch((err) => console.error("Error fetching collateral logos:", err));
  }, []);

  // Helper to get logo URL
  const getLogoUrl = (collateral) => {
    const match = collateralLogos.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  // Parse collateral data
  const parseCollateral = (collateral) => {
    if (
      typeof collateral === "string" &&
      collateral.startsWith("{") &&
      collateral.endsWith("}")
    ) {
      return collateral.slice(1, -1).split(",");
    }
    return Array.isArray(collateral) ? collateral : [collateral];
  };

  // Adjust transaction types dynamically
  const adjustTransactionType = (transactions, role) =>
    transactions.map((transaction) => {
      if (role === "borrow") {
        return {
          ...transaction,
          type:
            transaction.borrow_user_id === currentUserId ? "LEND" : "BORROW",
        };
      }
      if (role === "lend") {
        return {
          ...transaction,
          type: transaction.lend_user_id === currentUserId ? "BORROW" : "LEND",
        };
      }
      return transaction;
    });

  const getFilteredTransactions = () => {
    const adjustedBorrowTransactions = adjustTransactionType(
      borrowTransactions,
      "borrow"
    );
    const adjustedLendTransactions = adjustTransactionType(
      lendTransactions,
      "lend"
    );

    let allTransactions = [];

    if (filter === "borrow") {
      allTransactions = adjustedBorrowTransactions;
    } else if (filter === "lend") {
      allTransactions = adjustedLendTransactions;
    } else {
      allTransactions = [
        ...adjustedBorrowTransactions,
        ...adjustedLendTransactions,
      ];
    }

    // Sort all transactions by created_date in descending order (latest first)
    return allTransactions.sort(
      (a, b) => new Date(b.created_date) - new Date(a.created_date)
    );
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const formatDate = (dateString) =>
    dateString ? new Date(dateString).toLocaleDateString() : "--";

  const filteredTransactions = getFilteredTransactions();

  if (filteredTransactions.length === 0) {
    return (
      <div className="no-transactions">
        <p>You have not made any transactions yet, Get started!</p>
        <button
          className="get-started-button"
          onClick={() => navigate("/requests")}
        >
          Get Started
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="user-transactions">
        <h2>Your Transactions</h2>
        <div className="filter-container">
          <label>
            <input
              type="radio"
              name="filter"
              value=""
              checked={filter === ""}
              onChange={handleFilterChange}
            />
            All
          </label>
          <label>
            <input
              type="radio"
              name="filter"
              value="borrow"
              checked={filter === "borrow"}
              onChange={handleFilterChange}
            />
            Borrow
          </label>
          <label>
            <input
              type="radio"
              name="filter"
              value="lend"
              checked={filter === "lend"}
              onChange={handleFilterChange}
            />
            Lend
          </label>
        </div>
        <table className="transaction-table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Order ID</th>
              <th>Amount</th>
              <th>Duration</th>
              <th>Interest</th>
              <th>Collateral</th>
              <th>Status</th>
              <th>Participant</th>
              <th>Repayment Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction) => {
              const parsedCollateral = parseCollateral(transaction.collateral);
              return (
                <tr key={transaction.id}>
                  <td>{transaction.type.toLowerCase()}</td>
                  <td>
                    <Link to={`/transaction/${transaction.order_id}`}>
                      {transaction.order_id}
                    </Link>
                  </td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.duration} days</td>
                  <td>{transaction.interest}%</td>
                  <td>
                    <div className="collateral-cell">
                      {parsedCollateral.map((item, index) => (
                        <div key={index} className="collateral-item">
                          <img
                            src={getLogoUrl(item)}
                            alt={item}
                            className="collateral-logo"
                          />
                          <span>{item}</span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <span
                      className={`status status-${transaction.status
                        .replace(/_/g, "-")
                        .toLowerCase()}`}
                    >
                      {statusMap[transaction.status] || transaction.status}
                    </span>
                  </td>
                  <td>
                    {transaction.type === "BORROW" &&
                    transaction.lend_user_details ? (
                      <div className="participant-cell">
                        <img
                          src={transaction.lend_user_details.picture}
                          alt={transaction.lend_user_details.name}
                          className="participant-avatar"
                        />
                        <span>{transaction.lend_user_details.name}</span>
                      </div>
                    ) : transaction.type === "LEND" &&
                      transaction.borrow_user_details ? (
                      <div className="participant-cell">
                        <img
                          src={transaction.borrow_user_details.picture}
                          alt={transaction.borrow_user_details.name}
                          className="participant-avatar"
                        />
                        <span>{transaction.borrow_user_details.name}</span>
                      </div>
                    ) : (
                      <span>--</span>
                    )}
                  </td>
                  <td>{formatDate(transaction.repayment_date)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Routes>
        <Route
          path="/transaction/:orderId"
          element={
            <TransactionView
              transactions={[...borrowTransactions, ...lendTransactions]}
            />
          }
        />
      </Routes>
    </>
  );
};

export default UserTransactions;
