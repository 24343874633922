import React, { useEffect, useState } from "react";
import "./UserAccount.css";
import UserPayment from "./UserPayment/UserPayment";
import UserTransactions from "./UserTransactions/UserTransactions";
import UserDetails from "./UserDetails/UserDetails";

const UserAccount = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [borrowTransactions, setBorrowTransactions] = useState([]);
  const [lendTransactions, setLendTransactions] = useState([]);

  useEffect(() => {
    // Fetch user details from localStorage
    setUserDetails({
      username: localStorage.getItem("username"),
      profilePhoto: localStorage.getItem("picture"),
      email: localStorage.getItem("email"),
      token: localStorage.getItem("accessToken"),
    });
  }, []);

  useEffect(() => {
    if (userDetails?.token) {
      fetch("https://credita-backend.vercel.app/v1/credita/user/requests", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userDetails.token}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const currentUserId = parseInt(localStorage.getItem("userId"), 10); // Assuming `userId` is stored in localStorage.

          // Map transactions and update the type based on user role
          const mappedBorrowTransactions = (data.borrow || []).map(
            (transaction) => ({
              ...transaction,
              type:
                transaction.borrow_user_id === currentUserId
                  ? "BORROW"
                  : "LEND",
            })
          );

          const mappedLendTransactions = (data.lend || []).map(
            (transaction) => ({
              ...transaction,
              type:
                transaction.lend_user_id === currentUserId ? "LEND" : "BORROW",
            })
          );

          setBorrowTransactions(mappedBorrowTransactions);
          setLendTransactions(mappedLendTransactions);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [userDetails]);

  const totalBorrows = borrowTransactions.reduce(
    (acc, t) => acc + parseFloat(t.amount),
    0
  );
  const totalLends = lendTransactions.reduce(
    (acc, t) => acc + parseFloat(t.amount),
    0
  );
  const totalTransactions = borrowTransactions.length + lendTransactions.length;

  return (
    <>
      <h1 className="greeting">Hello {userDetails?.username || "User"}</h1>
      <div className="account">
        {userDetails && (
          <>
            <UserDetails
              user={userDetails}
              totalTransactions={totalTransactions}
              totalLends={totalLends}
              totalBorrows={totalBorrows}
            />
            <UserPayment payment={userDetails.payment} />
          </>
        )}
      </div>
      <div className="transactions">
        <UserTransactions
          borrowTransactions={borrowTransactions}
          lendTransactions={lendTransactions}
        />
      </div>
    </>
  );
};

export default UserAccount;
