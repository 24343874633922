import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import telegramIcon from "../../images/telegram.png";
import discord from "../../images/discord.jpg";
import xIcon from "../../images/x.png";
import "./FootBar.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <Container>
        <Row className="footer-content">
          <Col className="footer-section">
            <h5 className="footer-title">ABOUT</h5>
            <ul className="footer-links">
              <li onClick={() => handleNavigation("/about-us")}>About</li>
              <li onClick={() => handleNavigation("/contact-us")}>Contact us</li>
            </ul>
          </Col>
          <Col className="footer-section">
            <h5 className="footer-title">LEGAL</h5>
            <ul className="footer-links">
              <li>Terms of Service</li>
              <li>Terms and Conditions</li>
              <li>Privacy Policy</li>
            </ul>
          </Col>
          <Col className="footer-section">
            <h5 className="footer-title">PRODUCTS</h5>
            <ul className="footer-links">
              <li onClick={() => handleNavigation("/requests")}>Lend</li>
              <li onClick={() => handleNavigation("/requests")}>Borrow</li>
              <li onClick={() => window.open("https://xeodborn29t.typeform.com/to/R7l7LOGB", "_blank")}>Feedback</li>
            </ul>
          </Col>
          <Col className="footer-section">
            <h5 className="footer-title">FOLLOW US</h5>
            <div className="social-icons">
              <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
                <img src={telegramIcon} alt="Telegram" />
              </a>
              <a href="https://x.com/credita_finance" target="_blank" rel="noopener noreferrer">
                <img src={xIcon} alt="X" />
              </a>
              <a href="https://discord.gg/pfQvjc9u" target="_blank" rel="noopener noreferrer">
                <img src={discord} alt="Discord" />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col>
            <p>Credita Ltd © 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
