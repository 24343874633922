import React, { useState } from "react";
import goldBond1 from "../../images/goldBond1.svg";
import goldBond2 from "../../images/goldBond2.svg";
import goldBond3 from "../../images/goldBond3.svg";
import InvestmentOption from "../InvestmentOption/InvestmentOption";
import "./CreditaFeatures.css";

const CreditaFeatures = () => {
  const [typeFilter, setTypeFilter] = useState("borrow"); // Default to "borrow"

  const handleTypeFilterChange = (type) => {
    setTypeFilter(type);
  };

  return (
    <div className="credita-container">
      {/* Heading added above the filter */}
      <h2 className="feature-heading">How does it all come together?</h2>

      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="typeFilter"
            checked={typeFilter === "borrow"}
            onChange={() => handleTypeFilterChange("borrow")}
          />
          <span className="name">Borrow</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name="typeFilter"
            checked={typeFilter === "lend"}
            onChange={() => handleTypeFilterChange("lend")}
          />
          <span className="name">Lend</span>
        </label>
      </div>

      <div className="credita-content">
        <div
          className={`investment-options ${
            typeFilter === "borrow" ? "borrow-options" : "lend-options"
          }`}
        >
          {typeFilter === "borrow" && (
            <>
              <InvestmentOption
                imgSrc={goldBond1}
                imgWidth="80"
                imgHeight="40"
                heading="Decide your terms"
                description="Set your loan amount, Interest rate, and repayment duration"
              />
              <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="Secure Transactions"
                description="Funds released as soon as collateral is verified"
              />
               <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="More than +150 collateral options"
                description="Crypto, NFT’s, and fiat-backed guarantees. Place up to three different assets as collateral."
              />
            </>
          )}

          {typeFilter === "lend" && (
            <>
              <InvestmentOption
                imgSrc={goldBond3}
                imgWidth="80"
                imgHeight="40"
                heading="Full Control"
                description="Choose which loans to fund, set acceptable collateral, and decide your terms."
              />
              <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="Earn on your terms"
                description="Receive principal and interest directly from borrowers."
              />
               <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="Protected Investments"
                description="Collateral-backed loans ensure your funds are secure."
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditaFeatures;
