import React from "react";
import "./JoinCommunity.css";
import discordLogo from "../../images/discord.jpg"; // Replace with actual path
import twitterLogo from "../../images/logo-black.png"; // Replace with actual path
import t from "../../images/t.png"; // Replace with actual path

const JoinCommunity = () => {
  return (
    <div className="join-community-container">
      <h2 className="join-community-title">Ways to join our community</h2>
      <p className="join-community-description">
        Join our Discord, follow us on Twitter, and subscribe to our newsletter. That's all the alpha you need!
      </p>
      <div className="join-community-cards">
        {/* Discord Card */}
        <div className="community-card">
          <div className="card-content">
            <div className="logo-container">
              <img src={discordLogo} alt="Discord" className="community-logo" />
            </div>
            <h4 className="community-title">Join our Discord</h4>
            <p className="community-description">
              Become a part of a fantastic community. You will also find the Credita team there, ready to help you.
            </p>
          </div>
        </div>

        {/* Twitter Card */}
        <div className="community-card">
          <div className="card-content">
            <div className="logo-container">
              <img src={twitterLogo} alt="Twitter" className="community-logo" />
            </div>
            <h4 className="community-title">Follow us on Twitter</h4>
            <p className="community-description">
              The fastest way to receive updates on Credita, NFT finance, and the NFT space in general.
            </p>
          </div>
        </div>

          {/* Twitter Card */}
          <div className="community-card">
          <div className="card-content">
            <div className="logo-container">
              <img src={t} alt="Twitter" className="community-logo" />
            </div>
            <h4 className="community-title">Follow us on Telegram</h4>
            <p className="community-description">
              The fastest way to receive updates on Credita, NFT finance, and the NFT space in general.
            </p>
          </div>
        </div>
    
      </div>
    </div>
  );
};

export default JoinCommunity;
