import React from "react";
import "./HowItWorks.css";
import whatis from "../../images/whatis.png";
import lendnorisk from "../../images/lendnorisk.png";
import repayloan from "../../images/repayloan.png";
import borrowwithasset from "../../images/borrowwithasset.png";
import acceptloan from "../../images/acceptloan.png";

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <h2 className="how-it-works-title">How it Works</h2>
      <div className="how-it-works-steps">
        <div className="step">
          <a
            href="https://www.youtube.com/watch?v=whatis"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              img
              src={whatis}
              alt="What is Credita?"
              className="step-image"
            />
          </a>
          <h4 className="step-title">What is Credita?</h4>
        </div>
        <div className="step">
          <a
            href="https://www.youtube.com/watch?v=lendnorisk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={lendnorisk}
              alt="Lending Fiat Money"
              className="step-image"
            />
          </a>
          <h4 className="step-title">Lending Fiat Money</h4>
        </div>
        <div className="step">
          <a
            href="https://www.youtube.com/watch?v=borrowwithasset"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={borrowwithasset}
              alt="Borrowing by keeping digital assets"
              className="step-image"
            />
          </a>
          <h4 className="step-title">Borrowing by keeping digital assets</h4>
        </div>
        <div className="step">
          <a
            href="https://www.youtube.com/watch?v=acceptloan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={acceptloan}
              alt="Accepting a loan offer"
              className="step-image"
            />
          </a>
          <h4 className="step-title">Accepting a loan offer</h4>
        </div>
        <div className="step">
          <a
            href="https://www.youtube.com/watch?v=repayloan"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={repayloan}
              alt="Repaying the loan"
              className="step-image"
            />
          </a>
          <h4 className="step-title">Repaying the loan</h4>
        </div>
      </div>
      <p className="how-it-works-description">
        Credita is a decentralized platform where users can borrow and lend fiat
        currency. We aim to bridge the gap between traditional lenders and
        modern borrowers.
      </p>
    </div>
  );
};

export default HowItWorks;
