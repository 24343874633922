import React, { useState, useEffect } from "react";
import "./Faq.css";

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    fetch("/data/borrowfaq.json", {})
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch FAQ data");
        }
        return response.json();
      })
      .then((data) => {
        // Add an `open` key to each FAQ item for toggle functionality
        const faqDataWithState = data.map((faq) => ({ ...faq, open: false }));
        setFaqs(faqDataWithState);
      })
      .catch((error) => console.error("Error fetching FAQ data:", error));
  }, []);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : faq.open,
      }))
    );
  };

  return (
    <div className="faq-container">
      <h2 className="faq-title">Got Questions ?</h2>
      <p className="faq-subtitle">
        Navigate our FAQ section to get started easily.
      </p>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${faq.open ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span className="faq-toggle-icon">{faq.open ? "-" : "+"}</span>
              {faq.question}
            </div>
            {faq.open && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
