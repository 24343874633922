import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./RequestPage.css";
import LendRequest from "./Lend/LendRequest";
import BorrowRequest from "./Borrow/BorrowRequest";
import config from "../../config";

const RequestPage = () => {
  const [requestType, setRequestType] = useState("Borrow");
  const [amount, setAmount] = useState("");
  const [duration, setDuration] = useState("");
  const [interestRate, setInterestRate] = useState(5);
  const [cryptoData, setCryptoData] = useState([]);
  const [collateral, setCollateral] = useState([]);
  const [requiredCollateral, setRequiredCollateral] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [bearerToken, setBearerToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login"); // Redirect to login page if the user is not authenticated
      return;
    }

    setBearerToken(token);

    fetch("/data/borrowCrypto.json")
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => console.error("Error fetching crypto data:", error));
  }, []);

  const handleRequestTypeChange = (type) => {
    setRequestType(type);
    setAmount("");
    setDuration("");
    setInterestRate(5);
    setCollateral([]);
    setRequiredCollateral(null);
  };

  const handleSubmit = async () => {
    if (!amount || !duration || !bearerToken) {
      alert("All fields are required, and user must be authenticated.");
      return;
    }

    const numericAmount = parseFloat(amount);
    const numericDuration = parseInt(duration, 10);

    if (isNaN(numericAmount) || numericAmount <= 0) {
      alert("Amount must be a positive number.");
      return;
    }

    if (isNaN(numericDuration) || numericDuration <= 0) {
      alert("Duration must be a positive number.");
      return;
    }

    try {
      const fetchOrderId = async () => {
        const response = await fetch(
          `${config.baseUrl}/v1/credita/generate-order-id`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to generate order ID");
        }

        const data = await response.json();
        return data.order_id;
      };

      const orderId = await fetchOrderId();
      const requestData = {
        amount: numericAmount,
        duration: numericDuration,
        interest: parseFloat(interestRate),
        orderId: orderId,
        collateral: collateral.length > 0 ? collateral : ["None"],
      };

      const endpoint =
        requestType === "Borrow"
          ? `${config.baseUrl}/v1/credita/new/borrow`
          : `${config.baseUrl}/v1/credita/new/lend`;

      console.log(`Submitting ${requestType} Request Data:`, requestData);

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Server error details:", errorData);
        throw new Error(
          `Failed to create ${requestType.toLowerCase()} request: ${
            errorData.message
          }`
        );
      }

      navigate(
        requestType === "Borrow" ? "/user-account" : "/payment",
        requestType === "Lend" ? { state: { amount, orderId } } : undefined
      );
    } catch (error) {
      console.error(
        `Error submitting ${requestType.toLowerCase()} request:`,
        error
      );
      alert(
        `Failed to create ${requestType.toLowerCase()} request: ${
          error.message
        }`
      );
    }
  };

  return (
    <div className="request-page">
      <h1 className="page-title">Raise a New Request</h1>
      <div className="mode-selector">
        {["Borrow", "Lend"].map((type) => (
          <button
            key={type}
            className={`mode-option ${requestType === type ? "active" : ""}`}
            onClick={() => handleRequestTypeChange(type)}
          >
            {type}
          </button>
        ))}
      </div>
      <div className="form-container">
        <div className="form-content">
          {requestType === "Borrow" ? (
            <BorrowRequest
              amount={amount}
              setAmount={setAmount}
              duration={duration}
              setDuration={setDuration}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              cryptoData={cryptoData}
              selectedCollateral={collateral}
              setSelectedCollateral={setCollateral}
              requiredCollateral={requiredCollateral}
              setRequiredCollateral={setRequiredCollateral}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
            />
          ) : (
            <LendRequest
              amount={amount}
              setAmount={setAmount}
              duration={duration}
              setDuration={setDuration}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              collateral={collateral}
              setCollateral={setCollateral}
            />
          )}
        </div>
        <div className="info-container">
          <div className="info-box">
            {requestType === "Borrow" ? (
              <div>
                <h3 className="bold-text">Borrow Details</h3>
                <p>
                  <span className="bold-text">Amount you want to borrow:</span>{" "}
                  {amount || ""}
                </p>
                <p>
                  <span className="bold-text">Loan term:</span> {duration || ""}{" "}
                  days
                </p>
                <p>
                  <span className="bold-text">Interest rate:</span>{" "}
                  {interestRate}%
                </p>
                <p>
                  <span className="bold-text">Origination fee 1.5%:</span> $
                  {(amount * 0.015).toFixed(2) || ""} USD
                </p>
                <p>
                  <span className="bold-text">Collateral required:</span>{" "}
                  {requiredCollateral || ""}
                </p>
              </div>
            ) : (
              <div>
                <h3 className="bold-text">Lend Details</h3>
                <p>
                  <span className="bold-text">Amount you want to lend:</span>{" "}
                  {amount || ""}
                </p>
                <p>
                  <span className="bold-text">Loan term:</span> {duration || ""}{" "}
                  days
                </p>
                <p>
                  <span className="bold-text">Interest rate:</span>{" "}
                  {interestRate}%
                </p>
                <p>
                  <span className="bold-text">Collateral accepted:</span>{" "}
                  {collateral.join(", ") || "None"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="next-button-container">
        <button className="next-button" onClick={handleSubmit}>
          {requestType === "Borrow" ? "Submit" : "Pay Now"}
        </button>
      </div>
    </div>
  );
};

export default RequestPage;
